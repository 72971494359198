import type { TextSizeType } from 'type/common'

// 뉴스레터
export const NEWSLETTER_PERSONAL_DOCS = `텔링미(telling me)에서는 뉴스레터 발송과 관련하여 ｢개인정보보호법」 제15조 제1항 제1호, 제17조 제1항 제1호, 제23조 제1호, 제24조 제1항 제1호에 따라 아래와 같이 개인정보의 수집 ·이용 및 제3자 제공에 관하여 귀하의 동의를 얻고자 합니다.\n
■ 수집·이용 목적
◦ 뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 있습니다. 수집된 정보는 ｢텔링미(telling me) 뉴스레터｣ 발송 외 다른 목적으로 사용되지 않습니다. 
■ 수집·이용할 항목
◦ (필수항목) 성명, 연락처(E-Mail)
■ 보유·이용기간
◦ 위 개인정보는 정보 주체가 뉴스레터 구독 해지 시 즉시 파기합니다.
■ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
◦ 위 개인정보 중 필수항목의 수집․이용에 관해 거부할 수 있습니다. 단, 동의하지 않는 경우 본 뉴스레터 수신이 불가합니다.`

export const NEWSLETTER_AD_DOCS = `텔링미(telling me)의 제휴 콘텐츠, 프로모션, 이벤트 정보 등의 광고성 정보를 수신합니다.`

// 서비스 이용 약관 and 개인정보 이용방침
export const TERMS_OF_SERVICE_DOCS: Array<{ _typo: TextSizeType; _margin: string; content: string }> = [
  {
    _typo: 'c_b',
    _margin: '0 0 4px 0',
    content: `■ 제1장 총칙`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제1조(목적)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `이 약관은 TellingUS (이하 “회사”)가 운영하는 tellingme 서비스(이하 “텔링미”)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제2조(정의)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
    
① “이용자”란 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다.

② “나의 공간”이란 텔링미의 질문에 대하여 자발적으로 남긴 ‘답변’의 목록(’모두의 공간’에 공개하지 않은 ‘답변’을 포함합니다.)을 의미합니다.

③ “모두의 공간”이란 텔링미의 ‘이용자’가 텔링미의 질문에 대하여 자발적으로 답변한 후, ‘모두의 공간’에 공유하는 것에 동의한 ‘답변’의 목록을 의미합니다.

④ “답변”이란 텔링미의 질문을 받고 스스로 작성한 모든 글을 의미합니다.

⑤ “공유된 답변”이란 텔링미의 질문을 받고 스스로 작성한 후 ‘모두의 공간’에 공유한 모든 글을 의미합니다.

⑥ “아이디”란 ‘이용자’의 식별과 서비스 이용을 위하여 ‘이용자’가 정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.

⑦ “비밀번호”란 ‘이용자’가 부여받은 ‘아이디’와 일치되는 ‘이용자’임을 확인하고 비밀보호를 위해 ‘이용자’ 자신이 정한 문자 또는 숫자의 조합을 말합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제3조(약관 등의 명시와 설명 및 개정)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 본 약관의 내용을 텔링미의 회원가입 페이지 및 설정 페이지를 통해 이용자에게 통지하고, 그 내용에 동의한 이용자가 본 서비스에 가입하여 회원이 되었을 때 본 약관의 효력이 발생합니다.

② 회사는 필요한 경우 관계 법령을 위반하지 않는 범위에서 본 약관을 변경할 수 있으며, 약관 변경 시에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 최소 7일 전부터 알림 기능으로 공지합니다.

③ 회사는 제2항에 따라 변경된 약관을 공지하거나 통지하면서, 이용자에게 약관 변경 적용일까지 거부 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 이용자가 명시적으로 약관 변경에 대한 거부 의사를 표시하지 않았을 때 이용자가 변경 약관에 동의한 것으로 간주합니다. 변경 약관에 동의하지 않는 이용자에 대하여 서비스 이용이 불가할 수 있으며, 이용자는 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.

④ 회원은 약관 일부분만을 동의 또는 거부할  수 없습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제4조(회원가입)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 이용자는 회사에서 제공하는 회원가입 페이지에서 이 약관에 동의한다는 의사표시를 하고 회원정보를 기입함으로써 회원가입을 완료합니다.

② 회사는 이용자가 회원가입 페이지의 ‘동의합니다’ 버튼을 클릭한 경우 본 약관에 동의한 것으로 간주합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제5조(회원정보의 변경)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 이용자는 설정 페이지 내 ‘내 정보 수정하기’ 탭을 통해 언제든지 본인의 개인정보를 열람 및 수정할 수 있습니다.

② 회원이 가입 신청 시 기재한 개인정보 중 성별, 생년월일은 한 번 설정한 후 변경이 불가능합니다. 불가피한 사유 발생 시, 설정 페이지 하단 고객센터 전자우편을 통해서 해당 정보 변경을 요청할 수 있습니다.

③ 회사는 다음 각 호의 어느 하나에 해당하는 닉네임에 대해서는 승낙을 거절하거나 회원가입 이후 조치할 수 있습니다.

3-1. 회원이나 제3자의 개인정보를 포함하고 있는 경우

3-2. 타인에게 혐오감을 주는 표현이 반영된 경우

3-3. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제6조(회원 탈퇴 및 자격 상실 등)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 이용자는 언제든지 서비스 이용을 원하지 않는 경우 설정 페이지 내 ‘회원 탈퇴’ 탭을 통해 이용계약을 해지할 수 있습니다.

② 회원 탈퇴로 인해 이용자가 서비스 내에서 보유한 서비스 이용정보는 개인정보처리방침에 별도의 규정이 없는 한 모두 삭제되어 복구가 불가능하게 됩니다.

③ 회사는 이용자가 제 11조 1항을 반복적으로 위배할 경우 적절한 조치를 통해 자격을 임시 정지하거나 상실시킬 수 있습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제7조(회원에 대한 통지)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사가 이용자에게 통지를 하는 경우 개별 알림 기능으로 할 수 있습니다.

② 회사는 이용자 전체에게 통지를 하는 경우 7일 이상 알림 페이지 또는 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '32px 0 4px 0',
    content: `■ 제2장 개인정보 관리`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제8조(개인정보보호)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 서비스를 제공하기 위하여 이용자로부터 서비스 이용에 필요한 최소한의 개인정보(닉네임, 직업, 고민의 종류, 성별, 생년월일, MBTI)를 수집할 수 있습니다.

② 회사는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.

③ 회사는 관련 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력하며, 이용자의 개인정보 보호 및 사용에 대해서는 회사가 별도로 고지하는 <개인정보 처리방침>을 적용합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '32px 0 4px 0',
    content: `■ 제3장 이용계약 당사자의 의무`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제9조(회사의 의무)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 관련 법령, 이 약관에서 정하는 의무를 신의에 따라 성실하게 준수합니다.

② 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실∙훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제10조(회원의 ID 및 비밀번호에 대한 의무)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 제17조(개인정보보호)의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 이용자에게 있습니다.

② 이용자는 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.

③ 이용자는 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제11조(이용자의 의무)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 이용자는 다음 각 호에 해당하는 행위를 해서는 안됩니다.

1-1. 이용자의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을 침해하거나 침해할 우려가 있는 경우

1-2. 회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 서비스를 이용하는 행위

1-3. 서비스의 개인정보 기입 및 변경 시 허위정보, 또는 타인의 정보를 사용하는 경우

1-4. ‘모두의 공간’에서 동일 또는 유사한 답변 또는 의미가 없는 게시글을 반복적으로 개시하는 행위

1-5. 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게 그 이용을 허락하는 경우

1-6. 도박 등 사행행위를 하거나 유도하는 행위, 음란⋅저속한 정보를 교류⋅게재하거나 음란 사이트를 연결(링크)하는 행위, 수치심⋅혐오감 또는 공포심을 일으키는 말⋅ 소리⋅글⋅그림⋅사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등 서비스를 불건전하게 이용하는 행위

1-7. 회사의 운영진, 관리자 및 관계자를 사칭하는 행위

1-8. 기타 불법적이거나 부당한 수단과 목적에 의한 행위

② 회사는 이용자가 제1항에서 금지한 행위로 서비스를 이용할 경우 사용에 대한 제재 (이용정지 등)를 가할 수 있습니다.

③ 회원은 사이트에 등록된 본인의 회원 계정에 관한 접근 권한 및 접근 수단을 안전하게 관리하여야 하며, 회원의 귀책 사유로 인하여 발생한 계정의 도용과 서비스의 이용에 대한 책임을 부담합니다.

④ 회원은 본 약관 및 회사가 서비스와 관련하여 고지하는 내용을 준수하여야 하며, 약관 및 고지 내용을 위반하거나 이행하지 아니하여 발생하는 모든 손실, 손해에 대하여 책임을 부담합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '32px 0 4px 0',
    content: `■ 제4장 서비스 이용 및 제한`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제12조(서비스의 제공)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 제4조에 따라 이용계약이 완료된 이용자에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자 부터 서비스를 개시할 수 있습니다.

② 회사는 이용자에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.

③ 회사는 이용자의 등급을 구분하고 글자수 제한, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수 있습니다.

④ 회사가 제공하는 서비스에는 회사 이외의 광고주의 판촉활동을 위한 서비스가 포함되어 있으며, 회원은 본 약관을 체결함과 동시에 서비스의 이용 과정에서 자신이 작성한 게시물 등이 광고 게재에 활용될 수 있다는 점에 대해 동의한 것으로 간주됩니다.

⑤ 회사는 홈페이지에 게재된 광고주의 광고나 판촉활동의 진실성을 보장하지 않으며, 이용자가 홈페이지에 게재되어 있거나 본 서비스를 통하여 광고주의 판촉활동에 참여하여 거래한 결과로서 발생하는 손실과 손해에 대해서는 책임을 지지 않습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제13조(서비스의 변경 및 중단)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에 공지합니다. 다만, 버그나 오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.

② 회사는 영업 양도, 분할, 합병 등에 따른 영업의 폐지, 콘텐츠 제공의 계약만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스를 중단할 수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자 및 사유를 알림 페이지, 앱 초기 화면 또는 그 연결 화면 등 기타 서비스 제공 화면을 통해 공지하고 회원에게 통지합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제14조(광고의 제공)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사는 서비스의 제공을 위해 서비스 내부에 광고를 게재할 수 있습니다.

    ② 회사는 이용자의 이용 기록을 활용한 광고를 게재할 수 있습니다.
    
    ③ 회사는 이용자가 광고성 정보 수신에 명시적으로 동의한 경우, 이용자가 동의한 수단을 통해 광고성 정보를 발신할 수 있습니다.
    
    ④ 회사는 광고 게재 및 동의된 광고성 정보의 발신으로 인해 발생한 피해에 대해 회사의 고의 또는 중대한 과실이 없는 한 어떠한 책임도 지지 않습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제15조(저작권의 귀속)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사가 작성한 저작물에 대한 권리는 회사에 귀속되며, 이용자가 작성한 답변에 대한 권리는 회원에게 귀속됩니다.

② 이용자가 서비스 내 ‘모두의 공간’ 페이지에 답변을 공유하는 경우, 해당 답변은 서비스에 노출될 수 있고 필요한 범위 내에서 사용, 저장, 복제, 수정, 전시, 배포 등의 방식으로 해당 ‘공유된 답변’을 이용할 수 있도록 허락하는 전 세계적인 라이선스를 회사에 제공하게 됩니다. 이 경우, 회사는 저작권법을 준수하며 이용자는 언제든지 문의 창구를 이용하여 가능한 범위에 한해 해당 ‘공유된 답변’에 대한 삭제, 수정, 비공개 등의 조치를 취할 수 있습니다.

③ 회사는 제3항 이외의 방법으로 회원의 답변을 이용할 경우, 해당 회원으로부터 개별적이고 명시적인 동의를 받아야 합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제16조(서비스 이용 제한)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① ‘모두의 공간’ 페이지 내 ‘공유된 답변’이 욕설, 음란물, 광고, 낚시성 콘텐츠 등을 포함하거나 ‘공유된 답변’으로 인해 개인정보 침해, 명예훼손 등 권리가 침해된 경우, 누구든지 회사에 해당 게시물의 삭제 또는 반박내용의 게재를 요청할 수 있으며, 이때 회사는 해당 게시물을 삭제할 수 있습니다.

② 회사가 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회원에게 통지합니다. 이용제한 조치의 유형 및 기간은 누적된 신고 횟수에 따라 달라집니다.

2-1. 이용제한 조치의 사유

2-2. 이용제한 조치의 유형 및 기간

2-3. 이용제한 조치에 대한 이의신청 방법

③ 신고는 지적 재산권 침해를 신고하는 경우를 제외하고 익명으로 이루어집니다. 이용자가 신고한 계정은 누가 신고했는지를 확인할 수 없습니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제17조(이용제한 조치에 대한 이의신청 절차)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 이용자가 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날부터 14일 이내에 불복 이유를 설정 페이지 하단 고객센터 전자우편으로 전송하여야 합니다.

② 회사는 제1항의 이의신청서를 접수한 날부터 14일 이내에 불복 이유에 대하여 전자우편 또는 이에 준하는 방법으로 답변합니다.

③ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '32px 0 4px 0',
    content: `■ 제5장 손해배상 및 면책조항`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제18조(분쟁해결)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `① 회사 또는 이용자는 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

② 회사가 개별서비스 제공자와 제휴 계약을 맺고 이용자에게 개별서비스를 제공하는 경우에 이용자가 이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 이용자에게 손해가 발생한 경우에 그 손해에 대해서는 개별서비스 제공자가 책임을 집니다. 단, 회사에게 고의 또는 과실이 있는 경우는 그러하지 아니합니다.`
  },
  {
    _typo: 'c_b',
    _margin: '24px 0 16px 0',
    content: `<제19조(재판권 및 준거법)>`
  },
  {
    _typo: 'c',
    _margin: '0',
    content: `이 약관은 대한민국 법률에 따라 규율되고 해석됩니다.
회사와 이용자 간에 발생한 분쟁으로 소송이 제기되는 경우에는 민사소송법에 따라 관할권을 가지는 법원을 관할 법원으로 합니다.`
  }
]

export const PRIVACY_POLICY_DOCS: Array<{ _typo: TextSizeType; _margin: string; content: string }> = [
  {
    _typo: 'b2_b',
    _margin: '0 0 12px 0',
    content: `TellingUS (이하 “회사”)는 「개인정보 보호법」 제30조에 따라 tellingme 서비스(이하 “텔링미”)를 이용하는 이용자의 개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 32px 0',
    content: `○ 이 개인정보처리방침은 2023년 6월 22부터 적용됩니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제1조(개인정보의 처리 목적)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1. 서비스 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, Kakao 간편로그인, Apple 간편 로그인 목적으로 개인정보를 처리합니다.

2. 민원사무 처리
민원인의 신원 확인, 민원사항 확인 목적으로 개인정보를 처리합니다.

3. 서비스 개발 및 제공
서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.

4. 마케팅 및 광고에의 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인 등을 목적으로 개인정보를 처리합니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제2조(개인정보의 처리 및 보유 기간)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 서비스를 제공하는 동안 개인정보 처리방침 및 관련법에 의거하여 이용자의 개인정보를 지속적으로 관리 및 보관합니다. 탈퇴 등 개인정보 수집 및 이용목적이 달성될 경우, 수집된 개인정보는 즉시 또는 다음과 같이 일정 기간 이후 파기됩니다.

- 회원가입 및 서비스 이용과정에서 수집된 개인정보 : 탈퇴 후 1년
- 보유 목적 : 질의응답, 개인 컨텐츠, 문의하기`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제3조(개인정보 수집)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `1. 회원 가입 시 기본수집사항(필수항목): ID, 비밀번호, 닉네임, 직업, 고민의 종류
2. 회원 가입 시 기본수집사항(선택항목): 성별, 출생연도, MBTI
3. 서비스 이용과정에서 추가로 수집 가능한 정보: 성별, 출생연도, MBTI, 탈퇴 전적, 
4. 자동 수집 항목 :
방문기록, 접속IP정보, 서비스 이용기록. 광고식별자, 쿠키(쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자 PC 컴퓨터의 하드디스크에 저장되기도 합니다.)
5. [캐시 삭제 방법]
  - 아이폰의 경우 : 설정 > 일반 > 아이폰 스토리지 > tellingme > 앱정리하기       
  - 안드로이드의 경우 : 설정 > 애플리케이션 > tellingme> 저장공간`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제4조(개인정보의 제3자 제공에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제5조(개인정보처리의 위탁에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `① 회사는 원칙적으로 이용자 동의 없이 이용자의 개인정보를 외부에 공개하지 않습니다. 단 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공 및 위탁에 직접 동의한 경우는 예외로 합니다. 또한 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우에는 특정 개인을 알아볼 수 없는 통계 자료의 형태로 가공하여 제공할 수 있습니다.

② 회사는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
    
③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제6조(개인정보의 파기절차 및 파기방법)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.

② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.

③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차
회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제7조(이용자의 권리·의무 및 그 행사방법에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.

② 제1항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.

③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.

④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.

⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.

⑥ 회원 탈퇴 방법 : 설정 페이지>회원 탈퇴 탭`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제8조(개인정보의 안전성 확보조치에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

1. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

2. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제9조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `① 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다. 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 

② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제10조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제11조(추가적인 이용·제공 판단기준)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `회사는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다. 

이에 따라 회사가 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.

▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부

▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부

▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부

▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부

※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제12조(개인정보 보호책임자에 관한 사항)>`
  },
  {
    _typo: 'b2',
    _margin: '0 0 32px 0',
    content: `① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자
     | 성명 : 박소현
     | 담당부서 : 서비스기획
     | 연락처 :010-4842-0340, march1999@naver.com

② 정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

③ 또한 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.

- 개인정보침해신고센터: 국번 없이 118 (https://privacy.kisa.or.kr)
- 개인정보 분쟁조정위원회: 02-2100-2499 (http://kopico.go.kr)
- 대검찰청 사이버수사과: 국번 없이 1301 (http://www.spo.go.kr)
- 경찰청 사이버안전국: 국번 없이 182 (http://cyberbureau.police.go.kr)`
  },
  {
    _typo: 'b2_b',
    _margin: '0 0 16px 0',
    content: `<제13조(개인정보 처리방침의 개정 및 공지)>`
  },
  {
    _typo: 'b2',
    _margin: '0',
    content: `회사는 필요한 경우 관계 법령을 위반하지 않는 범위에서 본 약관을 변경할 수 있으며, 약관 변경 시에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 최소 7일 전부터 알림 기능으로 공지합니다.`
  }
]

// 세팅
export const SETTING_WITHDRAWAL_DOCS: Array<{ _typo: TextSizeType; _margin?: string; content: string }> = [
  {
    _typo: 'b2_b',
    _margin: '0 auto 8px 0',
    content: `💡 알고 계셨나요?`
  },
  {
    _typo: 'b2',
    _margin: '0 auto 4px 18px',
    content: `내가 쓴 답변은 탈퇴하지 않아도 수정 및 삭제가`
  },
  {
    _typo: 'b2',
    _margin: '0 auto 12px 18px',
    content: `가능해요.`
  },
  {
    _typo: 'b2_b',
    _margin: '0 auto 8px 0',
    content: `⛔ 계정을 삭제하면:`
  },
  {
    _typo: 'b2',
    _margin: '0 auto 2px 18px',
    content: `내가 쓴 소중한 모든 답변 기록들이 영구적으로`
  },
  {
    _typo: 'b2',
    _margin: '0 auto 0 18px',
    content: `사라져요.`
  },
  {
    _typo: 'b2',
    _margin: '0 auto 2px 18px',
    content: `모두의 공간에 공유된 답변은 자동으로 삭제되지 `
  },
  {
    _typo: 'b2',
    _margin: '0 auto 0 18px',
    content: `않으니 미리 확인해 주세요.`
  }
]
