export interface IEmotion {
  emotion100: string
  emotion200: string
  emotion300: string
  emotion400: string
  emotion500: string
  emotion600: string
}

const emotion: IEmotion = {
  emotion100: '#FFE26C',
  emotion200: '#B1F981',
  emotion300: '#87F0AE',
  emotion400: '#9B9BFF',
  emotion500: '#A0E4F5',
  emotion600: '#FAB0CF'
}

export default emotion
