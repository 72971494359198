export interface ISecondary {
  secondary25: string
  secondary50: string
  secondary100: string
  secondary200: string
  secondary300: string
  secondary400_main: string
  secondary500: string
  secondary600: string
  secondary700: string
  secondary800: string
}

const secondary: ISecondary = {
  secondary25: '#F4FBFE',
  secondary50: '#E9F6FD',
  secondary100: '#D2EDFB',
  secondary200: '#BCE5F8',
  secondary300: '#A5DCF6',
  secondary400_main: '#8FD3F4',
  secondary500: '#8BBAD1',
  secondary600: '#8599A3',
  secondary700: '#6494AB',
  secondary800: '#486A7A'
}

export default secondary
