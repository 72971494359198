export interface ISide {
  side100: string
  side200: string
  side300: string
  side400: string
  side500: string
}

const side: ISide = {
  side100: '#FFFDFA',
  side200: '#F9F7F2',
  side300: '#E6E4E2',
  side400: '#CCCAC8',
  side500: '#807F7D'
}

export default side
