export const PersonalAgreeData = `텔링미(telling me)에서는 뉴스레터 발송과 관련하여 ｢개인정보보호법」 제15조 제1항 제1호, 제17조 제1항 제1호, 제23조 제1호, 제24조 제1항 제1호에 따라 아래와 같이 개인정보의 수집 ·이용 및 제3자 제공에 관하여 귀하의 동의를 얻고자 합니다.\n
■ 수집·이용 목적
◦ 뉴스레터 발송을 위한 최소한의 개인정보를 수집하고 있습니다. 수집된 정보는 ｢텔링미(telling me) 뉴스레터｣ 발송 외 다른 목적으로 사용되지 않습니다. 
■ 수집·이용할 항목
◦ (필수항목) 성명, 연락처(E-Mail)
■ 보유·이용기간
◦ 위 개인정보는 정보 주체가 뉴스레터 구독 해지 시 즉시 파기합니다.
■ 동의를 거부할 권리 및 동의를 거부할 경우의 불이익
◦ 위 개인정보 중 필수항목의 수집․이용에 관해 거부할 수 있습니다. 단, 동의하지 않는 경우 본 뉴스레터 수신이 불가합니다.`

export const AdAgreeData = `텔링미(telling me)의 제휴 콘텐츠, 프로모션, 이벤트 정보 등의 광고성 정보를 수신합니다.`
